<template>
  <div class="datav-container">
    <div class="datav_header">
      <img
        class="datavimg"
        style="z-index: 1"
        src="../../assets/datavheader.png"
      />
      <div class="hrader_logo" style="z-index: 999">
        <img height="45px" src="../../assets/logo.png" />
      </div>
      <div class="header_title">实验室数据可视化</div>
      <div class="header_data" style="z-index: 999">{{ nowDateTime }}</div>
    </div>
    <div class="datav_boody">
      <el-row :gutter="30">
        <el-col :span="8">
          <div class="div-border proofingorder" style="height: 27vh">
            <img class="div-line" src="../../assets/line.png" />
            <div class="hbt">
              <img class="hbt-icon" src="../../assets/hicon.png" />打样订单
            </div>
            <div class="orderdata">
              <div
                style="
                  width: 32%;
                  height: 30%;
                  background: #0c1c35;
                  border-radius: 8px;
                "
              >
                <p style="font-size: 16px">订单池</p>
                <p style="color: #52ffff; font-size: 18px">
                  {{ sampleOrder.orderCount }}
                </p>
              </div>
              <div
                style="
                  width: 32%;
                  height: 30%;
                  background: #0c1c35;
                  border-radius: 8px;
                "
              >
                <p style="font-size: 16px">打样中</p>
                <p style="color: #fff000; font-size: 18px">
                  {{ sampleOrder.inProofingCount }}
                </p>
              </div>
              <div
                style="
                  width: 32%;
                  height: 30%;
                  background: #0c1c35;
                  border-radius: 8px;
                "
              >
                <p style="font-size: 16px">已完成</p>
                <p style="color: #52ffff; font-size: 18px">
                  {{ sampleOrder.completedCount }}
                </p>
              </div>
            </div>
            <div>
              <div style="font-size: 16px; margin: 20px 0px 10px 0px">
                完成百分比
              </div>
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="
                  Math.round(
                    (sampleOrder.completedCount / sampleOrder.orderCount) *
                      10000
                  ) / 100
                "
                v-if="
                  !isNaN(
                    Math.round(
                      (sampleOrder.completedCount / sampleOrder.orderCount) *
                        10000
                    ) / 100
                  )
                "
                color="#01E8EA"
              ></el-progress>
            </div>
          </div>
          <div class="threecircle" style="height: 19vh">
            <div>
              <div class="circle">{{ perCount }}</div>
              <div style="font-size: 16px">人均查色</div>
            </div>
            <div>
              <div class="circle">
                <!-- <el-progress type="dashboard" :percentage="56" :stroke-width="16" color="#01E8EA" stroke-linecap:square></el-progress> -->
                <div id="bing" style="width: 80px; height: 80px"></div>
                <div
                  class="bingtext"
                  style="font-size: 18px; margin-top: -80px"
                >
                  {{ divisionPercent }}%
                </div>
              </div>
              <div style="font-size: 16px">查色匹配率</div>
            </div>
            <div>
              <div class="circle">{{ divisionSampleCount }}</div>
              <div style="font-size: 16px">人均打样</div>
            </div>
          </div>
          <div
            class="div-border"
            style="height: calc(39vh - 15px); margin-bottom: 15px"
          >
            <img class="div-line" src="../../assets/line.png" />
            <div class="hbt">
              <img class="hbt-icon" src="../../assets/hicon.png" />打样动态
            </div>
            <div class="dydt-table-header">
              <div>订单号</div>
              <div>打样员</div>
              <div>当前状态</div>
            </div>
            <div class="dydt-table-body">
              <el-carousel
                indicator-position="outside"
                height="calc(39vh - 155px)"
                :pause-on-hover="true"
              >
                <el-carousel-item v-for="item in 3" :key="item">
                  <el-row v-for="ite in tableData[item - 1]" :key="ite.id">
                    <el-col :span="8" class="carousel">{{
                      ite.sampleOrderNo
                    }}</el-col>
                    <el-col :span="8" class="carousel">{{
                      ite.sampleName
                    }}</el-col>
                    <el-col
                      :span="8"
                      class="carousel"
                      v-if="ite.sampleState == '0'"
                      style="color: #ff0048"
                      >未分配</el-col
                    >
                    <el-col
                      :span="8"
                      class="carousel"
                      v-if="ite.sampleState == '1'"
                      style="color: #fff000"
                      >打样中</el-col
                    >
                    <el-col
                      :span="8"
                      class="carousel"
                      v-if="ite.sampleState == '2'"
                      style="color: #ff0048"
                      >待验证</el-col
                    >
                    <el-col
                      :span="8"
                      class="carousel"
                      v-if="ite.sampleState == '3'"
                      style="color: #52ffff"
                      >已完成</el-col
                    >
                    <el-col
                      :span="8"
                      class="carousel"
                      v-if="ite.sampleState == '4'"
                      style="color: #ff0048"
                      >验证驳回</el-col
                    >
                    <el-col
                      :span="8"
                      class="carousel"
                      v-if="ite.sampleState == '5'"
                      style="color: #fff000"
                      >待结单</el-col
                    >
                    <el-col
                      :span="8"
                      class="carousel"
                      v-if="ite.sampleState == '6'"
                      style="color: #ff0048"
                      >待打样</el-col
                    >
                  </el-row>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <el-row :gutter="30">
            <el-col
              :span="12"
              class="div-border"
              style="height: calc(55vh - 15px); margin-bottom: 15px"
            >
              <img class="div-line" src="../../assets/line.png" />
              <div class="hbt">
                <img
                  class="hbt-icon"
                  src="../../assets/hicon.png"
                />产品三维空间色点图
              </div>
              <div
                id="scatterplot"
                style="width: 100%; height: calc(55vh - 70px)"
              ></div>
            </el-col>
            <el-col :span="12">
              <div
                class="div-border"
                style="height: calc(25vh - 15px); margin-bottom: 15px"
              >
                <img class="div-line" src="../../assets/line.png" />
                <div class="hbt">
                  <img
                    class="hbt-icon"
                    src="../../assets/hicon.png"
                  />打样产品新增趋势
                  <div class="right">
                    近6月
                  </div>
                </div>
                <div
                  id="singlefold"
                  style="width: 100%; height: calc(25vh - 70px)"
                ></div>
                <!-- <div id="singlefold" style="width: 100%;height:25vh;"></div> -->
              </div>
              <div
                class="div-border"
                style="height: calc(30vh - 15px); margin-bottom: 15px"
              >
                <img class="div-line" src="../../assets/line.png" />
                <div class="hbt">
                  <img
                    class="hbt-icon"
                    src="../../assets/hicon.png"
                  />打样员效率
                  <div class="right">
                    <!-- 上月<i class="el-icon-caret-bottom"></i> -->
                    <el-dropdown @command="selecttype">
                      <span class="dropdown">
                        {{ type }}<i class="el-icon-caret-bottom"></i>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item command="1">上月</el-dropdown-item>
                          <el-dropdown-item command="2">本月</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </div>
                <div
                  id="histogram"
                  style="width: 100%; height: calc(30vh - 70px)"
                ></div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="margin-right: 0px">
            <el-col
              :span="24"
              class="div-border"
              style="height: calc(30vh - 15px); margin-bottom: 15px"
            >
              <img class="div-line" src="../../assets/line.png" />
              <div class="hbt">
                <img class="hbt-icon" src="../../assets/hicon.png" />打样趋势
                <div class="right_">
                  <div class="circle1"></div>
                  新增订单数
                  <div class="circle2"></div>
                  订单完成数
                </div>
              </div>
              <div
                id="bilinear"
                style="width: 100%; height: calc(30vh - 70px)"
              ></div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- <div id="main" style="width: 600px;height:400px;"></div> -->
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import "echarts-gl";
import datajosn from '@/assets/mock.json'
import {
  sampleDynamicStatistics,
  searchHistoryStatistics,
  sampleOrderStatistics,
  sampleProductAddTrendStatistics,
  sampleTrendStatistics,
  prooferEfficiencyStatistics,
} from "@/api/modules/datav";

export default {
  data() {
    return {
      nowDateTime: "", //当前日期时间
      sampleOrder: {}, //打样订单
      perCount: "", //人均查色
      divisionSampleCount: "", //人均打样
      divisionPercent: "", //匹配率
      tableData: [], //打样动态表格
      type: "上月",

      //饼图
      bing: {
        color: ["#52FFFF", "rgba(12, 21, 48, 0.5)"],
        series: [
          {
            type: "pie",
            radius: ["50%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            data: [{ value: 75 }, { value: 25 }],
          },
        ],
      },

      //单折线图
      singlefold: {
        tooltip: {
          trigger: 'axis'
        },
        color: ["#52FFFF"],
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: [],
          axisLabel: {
            color: "#C1C1C1",
          },
          axisLine: {
            lineStyle: {
              color: "#439AFF",
            },
          },
          splitLine: {
            show: true, //显示纵向网格线
            lineStyle: {
              color: "#2C3167",
            },
          },
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2C3167",
            },
          },
          axisLabel: {
            color: "#C1C1C1",
          },
          axisLine: {
            lineStyle: {
              color: "#439AFF",
            },
          },
        },
        series: [
          {
            data: [],
            type: "line",
            splitLine: {
              show: true,
            },
            itemStyle: {
              emphasis: {
                color: "#52FFFF",
                borderColor: "#52FFFF",
              },
            },
          },
        ],
        grid: {
          top: 10,
          bottom: 20,
        },
      },

      //双折线图
      bilinear: {
        color: ["#52FFFF", "#D6A30E"],
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "1%",
          right: 20,
          bottom: "3%",
          top: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          // boundaryGap: false,
          data: [],
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#C1C1C1",
          },
          axisLine: {
            lineStyle: {
              color: "#439AFF",
            },
          },
          splitLine: {
            show: true, //显示纵向网格线
            lineStyle: {
              color: "#2C3167",
            },
          },
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2C3167",
            },
          },
          axisLabel: {
            color: "#C1C1C1",
          },
          axisLine: {
            lineStyle: {
              color: "#439AFF",
            },
          },
        },
        series: [
          {
            name: "新增订单数",
            type: "line",
            stack: "总量",
            data: [],
          },
          {
            name: "订单完成数",
            type: "line",
            stack: "总量",
            data: [],
          },
        ],
      },

      //柱状图
      histogram: {
        color: ["#52FFFF"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: 10,
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#C1C1C1",
          },
          axisLine: {
            lineStyle: {
              color: "#439AFF",
            },
          },
          splitLine: {
            show: true, //显示纵向网格线
            lineStyle: {
              color: "#2C3167",
            },
          },
        },
        yAxis: {
          type: "category",
          data: [],
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#2C3167",
            },
          },
          axisLabel: {
            color: "#C1C1C1",
          },
          axisLine: {
            lineStyle: {
              color: "#439AFF",
            },
          },
        },
        series: [
          {
            type: "bar",
            data: [],
            barMaxWidth: "70%",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [
                    {
                      offset: 0,
                      color: "rgba(0, 252, 255, 0)", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "rgba(0, 252, 255, 0.6)", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(0, 252, 255, 1)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      },

      //散点图
      scatterplot: {},
    };
  },
  created() {
    this.currentTime();
    this.getsampleOrderStatistics();
    this.getsearchHistoryStatistics();
    this.getsampleDynamicStatistics();
    this.getsampleProductAddTrendStatistics();
    this.getsampleTrendStatistics();
    this.getprooferEfficiencyStatistics();
  },
  watch: {},
  mounted() {
    this.addChart();
  },
  methods: {
    drawimage() {
      var bing = echarts.init(document.getElementById("bing"));
      bing.setOption(this.bing, true);
    },
    drawsinglefold() {
      var singlefold = echarts.init(document.getElementById("singlefold"));
      singlefold.setOption(this.singlefold, true);
    },
    drawbilinear() {
      var bilinear = echarts.init(document.getElementById("bilinear"));
      bilinear.setOption(this.bilinear, true);
    },
    drawhistogram() {
      var histogram = echarts.init(document.getElementById("histogram"));
      histogram.setOption(this.histogram, true);
    },
    drawscatterplot() {
      var scatterplot = echarts.init(document.getElementById("scatterplot"));
      scatterplot.setOption(this.scatterplot, true);
    },
    selecttype(type) {
      if (type == "1") {
        this.type = "上月";
      } else {
        this.type = "本月";
      }
      this.getprooferEfficiencyStatistics();
    },
    getTime() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      this.nowDateTime =
        yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
    },
    currentTime() {
      setInterval(this.getTime, 500);
    },
    //打样订单信息
    getsampleOrderStatistics() {
      let params = {};
      sampleOrderStatistics(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.sampleOrder = data.data;
        } else {
          this.sampleOrder = {};
        }
      });
    },
    //人均查色，匹配率，人均打样数
    getsearchHistoryStatistics() {
      let params = {};
      searchHistoryStatistics(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.perCount = data.data.perCount;
          this.divisionSampleCount = data.data.divisionSampleCount;
          this.divisionPercent = data.data.divisionPercent.slice(0, 2);
          this.bing.series[0].data[0] = parseInt(this.divisionPercent);
          this.bing.series[0].data[1] = 100 - parseInt(this.divisionPercent);
          setTimeout(() => {
            this.drawimage();
          }, 0);
          // this.drawimage();
        } else {
          this.perCount = "";
          this.divisionSampleCount = "";
          this.divisionPercent = "";
        }
      });
    },
    //打样动态
    getsampleDynamicStatistics() {
      let params = {
        limit: 12,
      };
      sampleDynamicStatistics(params).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.data.length < 12) {
            let num = data.data.length % 12;
            for (item in num) {
              data.data.push({
                sampleName: "-",
                sampleOrderNo: "-",
                sampleState: "-",
              });
            }
          }
          this.tableData[0] = data.data.slice(0, 4);
          this.tableData[1] = data.data.slice(4, 8);
          this.tableData[2] = data.data.slice(8, 12);
          // console.log(this.tableData);
        } else {
          this.tableData[0] = [];
          this.tableData[1] = [];
          this.tableData[2] = [];
        }
      });
    },
    //打样新品新增趋势
    getsampleProductAddTrendStatistics() {
      let params = {};
      sampleProductAddTrendStatistics(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.singlefold.xAxis.data = data.data.map((item) => {
            return item.chinaDate;
          });
          this.singlefold.series[0].data = data.data.map((item) => {
            return item.sampleProductCount;
          });
          setTimeout(() => {
            this.drawsinglefold();
          }, 0);
          // this.drawsinglefold();
        } 
        // else {
        // }
      });
    },
    getsampleTrendStatistics() {
      let params = {};
      sampleTrendStatistics(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.bilinear.xAxis.data = data.data.map((item) => {
            return item.chinaDate;
          });
          this.bilinear.series[0].data = data.data.map((item) => {
            return item.orderCount;
          });
          this.bilinear.series[1].data = data.data.map((item) => {
            return item.proofCount;
          });
          setTimeout(() => {
            this.drawbilinear();
          }, 0);
          // this.drawbilinear();
        } else {
          console.log();
        }
      });
    },
    getprooferEfficiencyStatistics() {
      let params = {
        type: this.type == "上月" ? "1" : "2",
      };
      prooferEfficiencyStatistics(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.histogram.yAxis.data = data.data.map((item) => {
            return item.userName;
          }).reverse();
          this.histogram.series[0].data = data.data.map((item) => {
            return item.orderCount;
          }).reverse();
          this.drawhistogram();
        } 
        // else {
        // }
      });
    },
    addChart() {
      var app = {};

      var chartDom = document.getElementById("scatterplot");
      var myChart = echarts.init(chartDom, "dark");
      var option;

      var indices = {
        name: 0,
        group: 1,
        id: 16,
      };
      var schema = [
        { name: "L", index: 0 },
        { name: "A", index: 1 },
        { name: "B", index: 2 },
        { name: "calcium", index: 3 },
        { name: "vitaminc", index: 4 },
      ];
      var data;

      var fieldIndices = schema.reduce(function (obj, item) {
        obj[item.name] = item.index;
        return obj;
      }, {});
      //var data;
      var fieldNames = schema.map(function (item) {
        return item.name;
      });
      fieldNames = fieldNames.slice(2, fieldNames.length - 2);

      function getMaxOnExtent(data) {
        var colorMax = -Infinity;
        var symbolSizeMax = -Infinity;
        for (var i = 0; i < data.length; i++) {
          var item = data[i];
          var colorVal = item[fieldIndices[config.color]];
          var symbolSizeVal = item[fieldIndices[config.symbolSize]];
          colorMax = Math.max(colorVal, colorMax);
          symbolSizeMax = Math.max(symbolSizeVal, symbolSizeMax);
        }
        return {
          color: colorMax,
          symbolSize: 2,
        };
      }

      var config = (app.config = {
        xAxis3D: "A",
        yAxis3D: "B",
        zAxis3D: "L",
        color: "L",
        symbolSize: "vitaminc",

        onChange: function () {
          var max = getMaxOnExtent(data);
          if (data) {
            myChart.setOption({
              visualMap: [
                {
                  max: max.color / 2,
                },
                {
                  max: max.symbolSize / 2,
                },
              ],
              xAxis3D: {
                name: config.xAxis3D,
              },
              yAxis3D: {
                name: config.yAxis3D,
              },
              zAxis3D: {
                name: config.zAxis3D,
              },
              setOption:({
                global:{
                  viewControl:{
                    autoRotate:true
                  }
                }
              }),
              series: {
                dimensions: [
                  config.xAxis3D,
                  config.yAxis3D,
                  config.zAxis3D,
                  config.color,
                  config.symbolSiz,
                ],
                data: data.map(function (item, idx) {
                  return [
                    item[fieldIndices[config.xAxis3D]],
                    item[fieldIndices[config.yAxis3D]],
                    item[fieldIndices[config.zAxis3D]],
                    item[fieldIndices[config.color]],
                    item[fieldIndices[config.symbolSize]],
                    idx,
                  ];
                }),
              },
            });
          }
        },
      });
      app.configParameters = {};
      ["xAxis3D", "yAxis3D", "zAxis3D", "color", "symbolSize"].forEach(
        function (fieldName) {
          app.configParameters[fieldName] = {
            options: fieldNames,
          };
        }
      ),

          data =datajosn
          var max = getMaxOnExtent(data);
          myChart.setOption({
            tooltip: { 
              formatter: function(param) {  
                var value = param.value;  
                // return '<div> ' + value[2] + '(' + value[1] + ')' +  
                //     '</div>';  
                return '<div>' 
                  +'<p> L：'+value[2]+'</p>'
                  +'<p> A：'+value[0]+'</p>'
                  +'<p> B：'+value[1]+'</p>'
                  +'</div>'
              }  
            },
            visualMap: [
              {
                top: 10,
                calculable: true,
                dimension: 3,
                max: max.color / 2,
                inRange: {
                  color: datajosn.map(index =>{
                      // console.log(index[3]);
                      return index[3]
                    })
                  // color: [//颜色
                  //   "#1710c0",
                  //   "#0b9df0",
                  //   "#00fea8",
                  //   "#00ff0d",
                  //   "#f5f811",
                  //   "#f09a09",
                  //   "#fe0300",
                  // ],
                },
                textStyle: {
                  color: "#fff",
                },
              },
              {
                bottom: 10,
                calculable: true,
                dimension: 4,
                max: max.symbolSize / 2,
                inRange: {
                  symbolSize: [10, 40],
                },
                textStyle: {
                  color: "#fff",
                },
              },
            ],
            xAxis3D: {
              name: config.xAxis3D,
              type: "value",
            },
            yAxis3D: {
              name: config.yAxis3D,
              type: "value",
            },
            zAxis3D: {
              name: config.zAxis3D,
              type: "value",
            },
            grid3D: {
              axisLine: {
                lineStyle: {
                  color: "#fff",
                },
              },
              axisPointer: {
                lineStyle: {
                  color: "#ffbd67",
                },
              },
              viewControl: {
                autoRotate: true
                // projection: 'orthographic'
              },
            },
            series: [
              {
                type: "scatter3D",
                dimensions: [
                  config.xAxis3D,
                  config.yAxis3D,
                  config.zAxis3D,
                  config.color,
                  config.symbolSiz,
                ],
                data: data.map(function (item, idx) {
                  return [
                    item[fieldIndices[config.xAxis3D]],
                    item[fieldIndices[config.yAxis3D]],
                    item[fieldIndices[config.zAxis3D]],
                    item[fieldIndices[config.color]],
                    item[fieldIndices[config.symbolSize]],
                    idx,
                  ];
                }),
                symbolSize: 12,
                // symbol: 'triangle',
                itemStyle: {
                  borderWidth: 0.5,
                  borderColor: "rgba(255,255,255,0.8)",
                  normal: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(120, 36, 50, 0.5)',
                    shadowOffsetY: 5,
                  }
                },
                emphasis: {
                  itemStyle: {
                    color: "#fff",
                  },
                },
              },
            ],
          });
      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.datav-container {
  position: relative;
  box-sizing: border-box;
  padding: 0 15px 15px 15px;
  height: 100vh;
  color: #ffffff;
  width: 100%;
  min-width: 1300px;
  background: #001033;
  .datavimg {
    position: absolute;
    top: -1.5vh;
    left: 0px;
    width: 100%;
    height: 19vh;
    z-index: -1;
  }
  .datav_header {
    width: 100%;
    height: 15vh;
    background-image: url("../../assets/datavheader.png") no-repeat;
    background-size: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .header_title {
      position: relative;
      z-index: 999;
      font-size: 38px;
      letter-spacing: 6px;
      font-weight: normal;
      color: #52ffff;
    }
    .hrader_logo {
      position: absolute;
      top: 6vh;
      left: 8%;
    }
    .header_data {
      font-size: 16px;
      position: absolute;
      top: 6vh;
      right: 9%;
    }
  }
  .datav_boody {
    .proofingorder {
      .orderdata {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-align: center;
      }
    }
    .threecircle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 10px;
      .circle {
        font-size: 38px;
        font-weight: normal;
        color: #e7ffff;
        margin: 10px;
        height: 80px;
        line-height: 80px;
        width: 80px;
        border-radius: 50%;
        background: rgba(12, 21, 48, 0.5);
        border: 1px solid rgba(82, 255, 255, 0.3);
        box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
        text-shadow: 0 0 10px #078792, 0 0 20px #078792, 0 0 30px #078792,
          0 0 40px #078792;
      }
    }
    .dydt-table-header {
      width: 100%;
      height: 40px;
      background: #0d1d34;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
    .dydt-table-body {
      margin: 20px auto;
      width: 100%;
      height: calc(39vh - 160px);
      // line-height: 320%;
      background: #0d1d34;
    }
    .carousel {
      text-align: center;
      margin: 18px auto;
      font-size: 14px;
    }
    .hbt {
      margin-bottom: 10px;
      font-size: 18px;
      .hbt-icon {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .right {
        float: right;
        color: #01e8ea;
        letter-spacing: 3px;
        font-size: 16px;
      }
      .dropdown {
        color: #01e8ea;
        letter-spacing: 3px;
        font-size: 16px;
      }
      .right_ {
        float: right;
        font-size: 16px;
        letter-spacing: 2px;
        padding-right: 15px;
      }
      .circle1 {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        background: #52ffff;
        margin: 0px 10px;
      }
      .circle2 {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        background: #d6a30e;
        margin: 0px 10px;
      }
    }
  }
  .div-border {
    position: relative;
    padding: 15px;
    background: rgba(12, 21, 48, 0.5);
    border: 1px solid rgba(82, 255, 255, 0.3);
    box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
    border-radius: 8px;
    .div-line {
      width: 100%;
      height: 2px;
      position: absolute;
      top: -2px;
    }
  }
  .img {
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }
}
</style>
<style lang="scss">
.datav-container {
  .el-progress-bar__outer {
    background-color: #0c1c35 !important;
    overflow: visible !important;
    margin-top: 10px;
  }
  .el-progress-bar__innerText {
    color: #52ffff;
    font-size: 18px;
    font-weight: bold;
    margin-top: -45px;
  }
  .el-carousel__button {
    width: 10px;
    height: 10px;
    background: #00ffff;
    border-radius: 50%;
  }
  .el-dropdown-menu__item--divided::before {
    background: none;
  }
}
</style>